import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CommonSchema, MatOption, QueryParams } from '@interfaces';
import { Moment } from 'moment';
import { UserService } from '../../_pages_/users/service';
import { TagsService } from '../../_pages_/tags';
import { CorporationsService } from '../../_pages_/corporations/service/corporations.service';

interface Obj extends CommonSchema {
  [key: string]: any;
}

export interface FilterCmpOption<T> extends MatOption {
  value: keyof T & string;
  type: 'string' | 'date' | 'select';
  optionsArr?: MatOption[];
  validators?: ValidatorFn[];
}

export type FilterCmpSearchKey<T> = keyof T | MatOption<keyof T>;

const commonFilters: FilterCmpOption<CommonSchema>[] = [
  {
    value: 'createdAt',
    viewValue: 'created at',
    type: 'date',
  },
  {
    value: 'updatedAt',
    viewValue: 'updated at',
    type: 'date',
  },
  {
    value: '_id',
    viewValue: 'object ID',
    type: 'string',
    validators: [Validators.pattern(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i)],
  },
];

interface FiltersForm {
  [key: string]: { from: Moment | undefined; to: Moment | undefined } & {
    equal: (MatOption & string) | undefined;
  };
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() searchKeys: FilterCmpSearchKey<Obj>[] = [];
  @Input() filters: FilterCmpOption<Obj>[] = [];
  @Input() searchHidden = false;

  searchKeysOpts: MatOption<keyof Obj>[];
  initialFilters: FilterCmpOption<Obj>[] = [];
  availableFilters: FilterCmpOption<Obj>[] = [];
  selectedFilters: FilterCmpOption<Obj>[] = [];

  form = this.fb.group({
    query: ['', [Validators.minLength(2)]],
    searchKey: [null],
    filters: this.fb.group({}),
  });

  @Output() emitRequest = new EventEmitter<QueryParams>();

  constructor(
    private fb: FormBuilder,
    private userSrv: UserService,
    private tagSrv: TagsService,
    private corpSrv: CorporationsService
  ) {}

  ngOnInit() {
    // Prepare filters
    this.initialFilters = [...this.filters, ...commonFilters];
    this.fetchAvailableFilters();

    // Normalize search keys and set def value
    this.searchKeysOpts = this.searchKeys.map((key) => {
      if (typeof key === 'object') return key;
      if (typeof key === 'string') {
        // return { value: key.toLowerCase(), viewValue: key };
        return { value: key.toString(), viewValue: key };
      }
    });
    const searchKeyCtrl = this.form.get('searchKey');
    searchKeyCtrl.setValue(this.searchKeysOpts[0]);
    if (this.searchKeysOpts.length < 2) searchKeyCtrl.disable();
  }

  addFilter(filter: FilterCmpOption<Obj>) {
    if (this.filtersForm.contains(filter.value)) return;

    switch (filter.type) {
      case 'date':
        const dateCtrl = this.fb.group({ from: [null], to: [null] });
        this.filtersForm.addControl(filter.value, dateCtrl);
        break;
      case 'string':
      case 'select':
        const selectCtrl = this.fb.group({ equal: [null, filter.validators] });
        this.filtersForm.addControl(filter.value, selectCtrl);
        break;
    }
    this.selectedFilters.push(filter);
    this.fetchAvailableFilters();
  }

  get filtersForm(): FormGroup {
    return this.form.get('filters') as FormGroup;
  }

  removeFilter(filter: FilterCmpOption<Obj>, index: number) {
    this.filtersForm.removeControl(filter.value);
    this.selectedFilters.splice(index, 1);
    this.fetchAvailableFilters();
    this.submit();
  }

  fetchAvailableFilters() {
    this.availableFilters = this.initialFilters.filter(
      (f) => !this.selectedFilters.some((sf) => sf.value === f.value),
    );
  }

  ngOnChanges() {
     // Prepare filters
     this.initialFilters = [...this.filters, ...commonFilters];
     this.fetchAvailableFilters();

     // Normalize search keys and set def value
     this.searchKeysOpts = this.searchKeys.map((key) => {
       if (typeof key === 'object') return key;
       if (typeof key === 'string') {
         return { value: key.toLowerCase(), viewValue: key };
       }
     });
  }

  async submit() {
    if (this.form.invalid) return;
    const query = this.form.get('query').value;
    const searchKeys = [this.form.get('searchKey').value?.value];

    // prepare filters
    const filtersVal: FiltersForm = this.filtersForm.value;
    const filters: QueryParams['filters'] = {};
    for await (const f of this.selectedFilters) {
      const name = f.value;
      const { from, to, equal } = filtersVal[name];

      switch (f.type) {
        case 'date':
          filters[name] = {
            from: from?.toDate(),
            to: to?.toDate(),
          };
          break;
        case 'select':
          filters[name] = {
            equal: equal?.value,
          };
          break;
        case 'string':
          if (['user', 'userPosted'].includes(name)) {
            const val = filtersVal[name].equal;
            const data = await this.userSrv.getUserByEmail(val).toPromise();
            filters[name] = {
              equal: data._id || undefined,
            };
          } else if (['corporation', 'company'].includes(name)) {
            const val = filtersVal[name].equal;
            const data = await this.corpSrv.getCorporationByName(val).toPromise();
            filters[name] = {
              equal: data._id || undefined,
            };
          } else {
            filters[name] = {
              equal: filtersVal[name].equal || undefined,
            };
          }
          break;
      }
    }

    this.emitRequest.emit({ query, searchKeys, filters } as QueryParams);
  }
}
