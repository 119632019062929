import { Injectable } from '@angular/core';
import { ApiService } from '@services/api';
import { PaginatedData, QueryParams } from '@interfaces';
import { Observable } from 'rxjs';
import { Tag, TagCreateReq, TagParams, TagUpdateReq } from '..';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private apiSrv: ApiService) {}

  getAll(queryParams: QueryParams): Observable<PaginatedData<Tag>> {
    return this.apiSrv.get('/tag/paginated', { params: queryParams });
  }

  getAllNoPag(): Observable<Array<Tag>> {
    return this.apiSrv.get('/tag');
  }

  getOne(id: string): Observable<Tag> {
    return this.apiSrv.get(`/tag/${id}`);
  }

  getByTitle(title: string): Observable<Tag> {
    return this.apiSrv.get(`/tag/search-by-title/${title}`);
  }

  getParams(): Observable<TagParams> {
    return this.apiSrv.get(`/tag/params`);
  }

  create(tag: TagCreateReq): Observable<Tag> {
    return this.apiSrv.post(`/tag`, tag);
  }

  update(tag: TagUpdateReq): Observable<Tag> {
    return this.apiSrv.patch(`/tag`, tag);
  }

  delete(body: { id: string }): Observable<Tag> {
    return this.apiSrv.delete(`/tag`, { body });
  }
}
