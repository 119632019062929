import { CommonSchema, MatOption } from '@interfaces';

export enum TagType {
  TOPIC = 'topic',
  VIBE = 'vibe',
}

export enum VibeSubType {}

export enum TopicSubType {
  LIFESTYLE = 'lifestyle',
  ISSUES = 'issues',
  FEEDING = 'feeding',
  BABIES = 'babies',
}

export const SubTypes = { ...TopicSubType };

export interface TagParams {
  types: Array<{
    type: TagType;
    subTypes: typeof SubTypes[];
  }>;
}

class TagInterface {
  title: string;
  type: TagType;
  subType: typeof SubTypes;
  isPublic: boolean;
}

export interface Tag extends CommonSchema, TagInterface {}
export class Tag {}

export interface TagCreateReq extends TagInterface {}
export interface TagUpdateReq extends TagInterface {
  id: string;
}

export class TagMin {
  title: Tag['title'];
  _id: Tag['_id'];
}

export const tagTypeOpts: MatOption<TagType>[] = [
  {
    value: TagType.TOPIC,
    viewValue: TagType.TOPIC,
  },
  {
    value: TagType.VIBE,
    viewValue: TagType.VIBE,
  },
];

export const subTypeOpts: MatOption<TopicSubType>[] = [
  {
    value: TopicSubType.LIFESTYLE,
    viewValue: TopicSubType.LIFESTYLE,
  },
  {
    value: TopicSubType.ISSUES,
    viewValue: TopicSubType.ISSUES,
  },
  {
    value: TopicSubType.FEEDING,
    viewValue: TopicSubType.FEEDING,
  },
  {
    value: TopicSubType.BABIES,
    viewValue: TopicSubType.BABIES,
  },
];
