<app-filters
    (emitRequest)="filterOnRequest($event)"
    [searchKeys]="filterSearchKeys"
    [filters]="filters"
></app-filters>

<div class="card">
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef>avatar</th>
        <td mat-cell *matCellDef="let element">
          <img class="avatar" [src]="element.avatar" alt="" />
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>role</th>
        <td mat-cell *matCellDef="let element">{{ roleText(element?.role?.title) }}</td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>company</th>
        <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

       <ng-container matColumnDef="corporation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>corporation</th>
        <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ link: element.corporateId }"
            [routerLink]="CorporationsRoute.getDetailsPath(element.corporateId?._id)"
        >
          {{ element.corporateId?.title | optional }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>phone</th>
        <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
      </ng-container> -->

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>code</th>
        <td mat-cell *matCellDef="let element">{{ element.code }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>created</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | moment:'ll' }}</td>
      </ng-container>

      <ng-container matColumnDef="userType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>user type</th>
        <td mat-cell *matCellDef="let element">{{ element.corporateId ? 'corporate' : 'regular' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
          mat-row
          *matRowDef="let row; let element; columns: displayedColumns"
          [routerLink]="Route.getDetailsPath(element._id)"
      ></tr>
    </table>

  <app-waiting *ngIf="acts.isLoading"></app-waiting>
  </div>

  <app-no-data *ngIf="!dataSource.data.length"></app-no-data>
  <div class="table-paginator-wrapper">
    <div class="buttons">
      <app-universal-button btnTitle="add new" icon="add" [routerLink]="Route.ADD_PATH"></app-universal-button>
      <app-universal-button btnTitle="Export" icon="save" (click)="csv_export()"></app-universal-button>
    </div>
    <mat-paginator [pageSizeOptions]="Paginator.PageSizeOpts" showFirstLastButtons></mat-paginator>
  </div>
</div>

