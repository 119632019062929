import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { RepliesRoute, Paginator, UsersRoute, PostsRoute } from '@const';
import { MatTableDataSource } from '@angular/material/table';
import { FilterCmpOption, FilterCmpSearchKey } from '@components/filters';
import { QueryService } from '@services/query';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { QueryFilters, QueryParams } from '@interfaces';
import { Reply } from '../../interfaces/reply.interface';
import { RepliesService } from '../../service/replies.service';
import { SnackbarService } from '@services/snackbar';

type ReplyProps = keyof Reply;

@Component({
  selector: 'app-replies-list',
  templateUrl: './replies-list.component.html',
  styleUrls: ['./replies-list.component.scss'],
  providers: [QueryService],
})
export class RepliesListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // pass predefined query filters
  @Input() queryFilters: QueryFilters = {};

  @Input() postId = null;

  Paginator = Paginator;
  Route = RepliesRoute;
  UsersRoute = UsersRoute;
  PostsRoute = PostsRoute;

  dataSource = new MatTableDataSource<Reply>([]);
  displayedColumns: ReplyProps[] = [
    'text',
    'user',
    'subject',
    'isPublic',
    'createdAt',
  ];

  filterSearchKeys: FilterCmpSearchKey<Reply>[] = ['text'];
  filters: FilterCmpOption<Reply>[] = [
    {
      value: 'user',
      viewValue: 'author\'s email',
      type: 'string',
    },
  ];

  acts = {
    isLoading: true,
  };

  constructor(
    private replySrv: RepliesService,
    private querySrv: QueryService,
    private snackBarSrv: SnackbarService,
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const request = (queryParams: QueryParams) => {
      if (this.queryFilters) {
        queryParams.filters = { ...queryParams.filters, ...this.queryFilters };
      }
      return this.replySrv.getAll(queryParams);
    };

    this.querySrv
      .register({
        sort: this.sort,
        paginator: this.paginator,
        request,
        acts: this.acts,
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        ({ data }) => (this.dataSource = new MatTableDataSource<Reply>(data)),
      );
    this.querySrv.initSearch();
  }

  filterOnRequest(params: QueryParams) {
    this.paginator.pageIndex = 0;
    this.querySrv.initSearch(params);
  }

  add() {
    this.snackBarSrv.info();
  }

  ngOnDestroy() {
    this.querySrv.destroy();
  }
}
